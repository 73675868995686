import React, { useState, useEffect, useRef } from "react"

const ChainSelector = ({ items, onItemSelected }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (!selectedItem && items && items.length > 0) {
      setSelectedItem(items[0])
    }
    document.addEventListener("mousedown", handleDocumentClick)
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick)
    }
  }, [items, selectedItem])

  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  const handleItemClick = (item) => {
    setSelectedItem(item)
    setIsOpen(false)
    onItemSelected(item.chainId)
  }

  return (
    <div className="chain-selector">
      <div className="image-container" onClick={() => setIsOpen(!isOpen)}>
        {selectedItem ? (
          <img
            className="selected-image"
            src={selectedItem.image}
            alt={selectedItem.text}
          />
        ) : (
          "Select an item"
        )}
        {isOpen && (
          <div className="dropdown" ref={dropdownRef}>
            {items.map((item, index) => (
              <div key={index} onClick={() => handleItemClick(item)}>
                <img src={item.image} alt={item.text} />
                {item.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ChainSelector
